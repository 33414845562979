import API from '../../modules/API';
import isEmpty from '../../modules/isEmpty';

const LIMIT = 10;

export default () => {
	return {
		name: 'Contacts',
		template: require('./contacts.html'),
		data() {
			return {
				searchContact: '',
				currentPage: 0,
				contactList: {},
				contactsError: null,
			};
		},
		watch: {
			'searchContact'() {
				this.onSearchContact();
			},
		},
		async created() {
			await this.getContacts();
		},
		methods: {
			async getContacts() {
				const query = {
					$sort: {
						_dateInsert: -1,
					},
				};

				query.$skip = this.currentPage * LIMIT;
				if (query.$skip < 0) {
					query.$skip = 0;
				}

				if (isEmpty(this.searchContact) === false) {
					query.$skip = 0;
					query.$or = [
						{company: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{firstName: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{name: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
						{email: {
								$search: '(?i).*' + this.searchContact + '.*',
							},
						},
					];
				}

				this.contactsError = null;
				try {
					this.contactList = await API.getContacts(query);
				} catch (exception) {
					this.contactsError = exception;
				}
			},
			async deleteContact(id) {
				this.contactsError = null;
				try {
					await API.removeContact(id);
					await this.getContacts();
				} catch (exception) {
					this.contactsError = exception;
				}
			},
			hasPrev() {
				return this.contactList.skip > 0;
			},
			hasNext() {
				return this.contactList.total > ((this.contactList.skip + 1) * this.contactList.limit);
			},
			async prevPage() {
				this.currentPage--;
				await this.getContacts();
			},
			async nextPage() {
				this.currentPage++;
				await this.getContacts();
			},
			async onSearchContact() {
				await this.getContacts();
			},
		},
	};
};
