
export default {
	name: 'ImmShowActu',
	template: require('./imm-show-actu.html'),
	props: ['actu', 'displayFull'],
	data() {
		return {
		};
	},
	created() {
	},
	mounted() {
	},
	methods: {
	},
	computed: {
		getDesc() {
			const desc = this.actu && this.actu.desc ? this.actu.desc.replaceAll('\n', '<br>') : '';

			if (this.displayFull !== false) {
				return desc.substr(0, 100) + '...';
			}
			return desc;
		},
	},
};
