import {createApp, reactive} from 'vue/dist/vue.esm-bundler';

// modules
import config from './modules/config';
import router from './modules/router';
import isEmpty from './modules/isEmpty';
import formatDate from './modules/formatDate';
import errorManager from './modules/errorManager';
import API from './modules/API';

// components
import header from './components/header';
import footer from './components/footer';

const app = createApp({template: require('./pages/_layout.html')});

// vue globals
app.config.globalProperties.Config = config;
app.config.globalProperties.isEmpty = isEmpty;
app.config.globalProperties.formatDate = formatDate;
app.config.globalProperties.errorManager = errorManager;
app.config.globalProperties.$authUser = reactive({
	user: null,
});
app.config.globalProperties.isConnected = () => {
	return app.config.globalProperties.$authUser.user !== null;
};

app.use(router);

app.component('app-header', header());
app.component('app-footer', footer());

(async() => {
	const auth = await API.authenticate();
	if (auth !== null && typeof auth !== 'undefined') {
		app.config.globalProperties.$authUser.user = auth.user;
	}
	
	app.mount('#app');
})();
