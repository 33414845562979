import API from '../modules/API';
import ImmContact from './imm-contact';

export default {
	name: 'ImmShowBreve',
	template: require('./imm-show-breve.html'),
	components: {
		ImmContact
	},
	props: ['breve'],
	data() {
		return {
			categories: {
				object: 'Objet',
				project: 'Projet',
				// localisation
				address: 'Adresse',
				address_line1: 'Complément d\'adresse',
				postcode: 'Code postal',
				city: 'Ville',
				county: 'Département',
				state: 'Région',
				// infos
				challenges: 'Enjeux',
				programmation: 'Programmation',
				comment: 'Commentaire',
				desc: 'Infos',
				context: 'Contexte',
				link: 'Lien',
				area: 'Superficie',
				amount: 'Montant',
				// calendar
				subscription: 'Candidature et offre',
				posted: 'Dépôt Candidature',
				analysed: 'Analyse',
				audited: 'Audition des candidats retenus après la 1ère phase',
				closed: 'Remise des offres finales',
			},
			contactList: {
				total: 0,
				data: [],
			},
		};
	},
	async created() {
		if (this.isEmpty(this.breve.contacts) === false) {
			try {
				this.contactList = await API.getContacts({
					id: { $in: this.breve.contacts }
				});
			} catch (_) {
				this.contactList = { total: 0, data: [] };
			}
		}
	},
	methods: {
		format(category, value) {
			switch (category) {
				case '_dateLastUpdate': // eslint-disable-line
					return new Date(value).toLocaleDateString(); // eslint-disable-line
				case 'amount': // eslint-disable-line
					return value + ' €'; // eslint-disable-line
			}
			if (typeof value === 'string') {
				return value.replace('\n', '<br>');
			}
			return value;
		}
	}
};
