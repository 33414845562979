
export default () => {
	return {
		template: require('./about.html'),
		data() {
			return {
				team: [
					{
						name: 'Matthieu',
						image: '/assets/img/Matthieu.jpg',
						position: 'Directeur Pôle Rédaction et Développement',
						mail: this.Config.contactEmail,
						phone: '0662824565'
					}, {
						name: 'Maxime',
						image: '/assets/img/Maxime.jpg',
						position: 'Chef de projet',
					}, {
						name: 'Laurent',
						image: '/assets/img/profile.jpg',
						position: 'Architecte Logiciel',
					}, {
						name: 'Alex',
						image: '/assets/img/Alexandre.jpg',
						position: 'Graphiste',
					},
				]
			};
		},
		created() {
		},
		mounted() {
		},
		methods: {
		},
	};
};
