import API from '../modules/API';
import ImmAddress from './imm-address';
import ImmContactList from './imm-contact-list';

export default {
	name: 'ImmFormBreve',
	template: require('./imm-form-breve.html'),
	components: {ImmAddress, ImmContactList},
	props: ['modelValue'],
	data() {
		return {
			publicFields: [
				'object',
				'project',
				'area',
			],
			_breve: {
				onlySubscriber: false,
				status: 'waiting',
			},
			formError: null,
			loadingCommit: false,
			addressSuggestions: [],
			linkError: false,
			addressSearchDisabled: false,
			// file upload
			fileName: '',
			breve: null,
		};
	},
	computed: {
		isEdit() {
			return this.isEmpty(this.modelValue) === false;
		},
		published: {
			get() {
				return this.breve.status === 'published';
			},
			set(v) {
				this.breve.status = v === true ? 'published' : 'waiting';
			}
		}
	},
	mounted() {
		this.breve = this.getBreve();
		
		this.addressSearchDisabled = this.isEmpty(this.breve.address_line1) === false && this.breve.address_line1.length > 0;
	},
	methods: {
		getBreve() {
			const breve = (this.isEdit === true) ? this.modelValue : this._breve;
			if (this.isEmpty(breve.contacts) === true) {
				breve.contacts = [];
			}
			return breve;
		},
		async onBreveAdd() {
			this.formError = null;
			this.loadingCommit = true;
			try {
				if (this.isEdit === true) {
					await API.editBreve(this.modelValue.id, this.breve);
				} else {
					await API.addBreve(this.breve);
				}
				this.$router.replace('/profil/breves/');
			} catch (exception) {
				this.formError = exception;
			} finally {
				this.loadingCommit = false;
			}
		},
		checkLink() {
			this.linkError = false;
			this.formError = null;

			if (this.isEmpty(this.breve.link) === false) {
				const regex = /^(http|https):\/\/[^ "]+$/;
				if (regex.test(this.breve.link) === false) {
					this.formError = 'Veuillez entrer un lien valide';
					this.linkError = true;
				}
			}
		},
		handleFileChange(event) {
			this.fileName = '';
			const file = event.files[0];
			if (this.isEmpty(file) === true) {
				return;
			}

			this.fileName = file.name;

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (e) => {
				// base64 image encoding
				this.breve.imageAd = e.target.result;
			};
		},
	},
};
