import API from '../../modules/API';
import isEmpty from '../../modules/isEmpty';

const LIMIT = 10;

export default () => {
	return {
		name: 'Actus',
		template: require('./actus.html'),
		data() {
			return {
				actuGroup: '',
				searchActu: '',
				currentPage: 0,
				actuResults: {},
				counters: {
					actusPublished: null,
					actusWaiting: null,
				},
				actusError: null,
			};
		},
		watch: {
			'searchActu'() {
				this.onSearchActu();
			},
		},
		async created() {
			await this.getActus();
		},
		methods: {
			async getActus() {
				const query = {
					status: '*',
					$sort: {
						_dateInsert: -1,
					},
				};

				query.$skip = this.currentPage * LIMIT;
				if (query.$skip < 0) {
					query.$skip = 0;
				}

				if (isEmpty(this.actuGroup) === false) {
					if (this.actuGroup === 'ads') {
						query.adOn = true;
					} else {
						query.status = this.actuGroup;
					}
				}

				if (isEmpty(this.searchActu) === false) {
					query.$skip = 0;
					query.$or = [
						{title: {
								$search: '(?i).*' + this.searchActu + '.*',
							},
						},
					];
				}

				this.actusError = null;
				try {
					this.actuResults = await API.getActus(query);
				} catch (exception) {
					this.actusError = exception;
				}
			},
			async deleteActu(id) {
				this.actusError = null;
				try {
					await API.removeActu(id);
					await this.getActus();
				} catch (exception) {
					this.actusError = exception;
				}
			},
			hasPrev() {
				return this.actuResults.skip > 0;
			},
			hasNext() {
				return this.actuResults.total > ((this.actuResults.skip + 1) * this.actuResults.limit);
			},
			async prevPage() {
				this.currentPage--;
				await this.getActus();
			},
			async nextPage() {
				this.currentPage++;
				await this.getActus();
			},
			isPublished(actu) {
				return actu.status === 'published';
			},
			async togglePublished(actu) {
				const status = this.isPublished(actu) === true ? 'waiting' : 'published';

				this.actusError = null;
				try {
					await API.editActu(actu.id, {status});
					actu.status = status;
				} catch (exception) {
					this.actusError = exception;
				}
			},
			async onGroupChange(group) {
				this.currentPage = 0;
				this.actuGroup = group;
				this.searchActu = '';

				await this.getActus();
			},
			async onSearchActu() {
				await this.getActus();
			},
		}
	};
};
