import autocompletePlaces from '../modules/autocompletePlaces';

export default {
	name: 'ImmAddress',
	template: require('./imm-address.html'),
	props: ['modelValue', 'hideLabel', 'disableSearch'],
	data() {
		return {
			isLoadingAddress: false,
			itemClicked: false, // used to check if a contact action had been done
			showList: false, // show and hide search list
			addressSuggestions: [],
			publicFields: [
				'state',
				'county',
			],
		};
	},
	computed: {
		showLabel() {
			return this.hideLabel !== true;
		},
		searchIsDisabled() {
			return this.isEmpty(this.disableSearch) !== true && this.disableSearch === true;
		},
		showComplements() {
			return this.searchIsDisabled === true || (this.isEmpty(this.modelValue.address_line1) === false && this.modelValue.address_line1.length > 0);
		}
	},
	methods: {
		chooseAddress(addressSuggestion) {
			this.modelValue.city = addressSuggestion.city;
			this.modelValue.postcode = addressSuggestion.postcode;
			this.modelValue.county = addressSuggestion.county;
			this.modelValue.state = addressSuggestion.state;
			this.modelValue.address = addressSuggestion.formatted;
			this.itemClicked = false;
			this.clearSuggestions();
		},
		async searchAddress() {
			if (this.searchIsDisabled === false) {
				if (this.isEmpty(this.modelValue.address) === false) {
					this.isLoadingAddress = true;
					this.addressSuggestions = await autocompletePlaces(this.modelValue.address);
					this.isLoadingAddress = false;
					if (this.addressSuggestions.length > 0) {
						this.showList = true;
					}
				} else {
					this.clearSuggestions();
				}
			}
		},
		clearSuggestions() {
			this.addressSuggestions = [];
			this.showList = false;
		},
		resetAddress() {
			this.modelValue.address_line1 = '';
			this.modelValue.address_line2 = '';
			this.modelValue.city = '';
			this.modelValue.postcode = '';
			this.modelValue.county = '';
			this.modelValue.state = '';
			this.modelValue.address = '';
		}
	}
};
