import isEmpty from './isEmpty';

export const twoChars = (val) => {
	return ('0' + val).slice(-2);
};

export default (value, format = 'YYYY-MM-DD') => {
	if (isEmpty(value) === true) {
		return value;
	}
	const date = new Date(value);

	switch (format) {
		case 'YYYY':
			return `${date.getFullYear()}`;
		case 'MM/DD':
			return `${twoChars(date.getMonth() + 1)}/${twoChars(date.getDate())}`;
		case 'DD/MM':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}`;
		case 'DD/MM/YY':
			return `${twoChars(date.getDate())}/${twoChars(date.getMonth() + 1)}/${date.getFullYear()}`;
		case 'YYYY-MM-DD':
			return `${date.getFullYear()}-${twoChars(date.getMonth() + 1)}-${twoChars(date.getDate())}`;
	}
	return value;
};
