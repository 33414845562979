
export default () => {
	return {
		template: require('/src/pages/mentions.html'),
		data() {
			return {
			};
		},
		created() {
		},
		methods: {
		},
	};
};
