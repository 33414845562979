import API from '../modules/API';
import Config from '../modules/config';
import ImmShowBreve from '/src/components/imm-show-breve';
import ImmShowActu from '/src/components/imm-show-actu';
import ImmCarouselAd from '/src/components/imm-carousel-ad';

export default () => {
	return {
		name: 'Home',
		template: require('/src/pages/home.html'),
		components: {
			ImmShowBreve,
			ImmShowActu,
			ImmCarouselAd,
		},
		data() {
			return {
				// projects
				breveList: null,
				breveError: null,
				// actus
				actus: null,
				actusError: null,
				// ads
				counterBreves: 3,
				counterActus: 3,
			};
		},
		created() {
			this.getBreves();
			this.getActus();
		},
		mounted() {
			document.title = `Accueil - ${Config.appName}`;
		},
		methods: {
			async getBreves() {
				this.breveError = null;
				try {
					this.breveList = await API.getBreves({
						$limit: this.counterBreves,
						adOn: '$hasnotfieldorfalse',
					});
				} catch (exception) {
					this.breveError = exception;
				}
			},
			async getActus() {
				this.actusError = null;
				try {
					this.actus = await API.getActus({
						$limit: this.counterActus,
						adOn: '$hasnotfieldorfalse',
					});
				} catch (exception) {
					this.actusError = exception;
				}
			},
		},
	};
};
